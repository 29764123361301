:root {
  font-size: 16px;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  font-family: inherit;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 640px) {
  br {
    display: none;
  }
}