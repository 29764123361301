/* ---------- import abstract folders ----------- */
@import "./abstracts/_variable.css";
@import "./base/_base.css";
@import "./base/_typography.css";

/* ------------ import base folder ------------ */
@import "tailwindcss/components";
/* ---------- import component folder ---------- */
@import "tailwindcss/components";

/* ----------- import utilities folder ---------- */
@import "tailwindcss/utilities";

html {
  line-height: 1.5;
  scroll-behavior: smooth;
  font-synthesis: none;
  font-weight: 400;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: "SFPro", sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  /* 4 */
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  /* 5 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
  color: #000;
}

.btn {
  min-width: 100px;
  border: none;
  cursor: pointer;
}

.btn-sm {
  height: 40px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
}

.btn-md {
  height: 45px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
}

.accordion-body {
  animation: fadeIn 0.3s ease-in-out;
}

.accordion-icon {
  cursor: pointer;
  text-align: left;
  color: black;
  box-sizing: border-box;
  font-size: 1.5rem;
  padding: 20px 14px;
  border-radius: 50%;
  transition: transform 0.2s linear 0s;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
}

.accordion-icon.active {
  transform: rotate(45deg);
  background-color: black;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  8.33% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    transform: translateY(0);
    opacity: 1;
  }

  33.33% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  8.33% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    transform: translateY(0);
    opacity: 1;
  }

  33.33% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.splide__slide {
  opacity: 0.4;
}

.splide__slide .quote-svg-path {
  fill: #f6dbad;
}

.splide__slide.is-active {
  opacity: 1;
}

.splide__slide.is-active .quote-svg-path {
  fill: #1b3300;
}
